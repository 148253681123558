const initialState = {
  isLoadingNotifications: false,
  errorNotifications: false,
  successNotifications: false,
  dataNotifications: false,
};

export const notificationsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "NOTIFICATIONS_LOADING":
      return {
        ...state,
        isLoadingNotifications: true,
        errorNotifications: false,
        successNotifications: false,
        dataNotifications: false,
      };
    case "NOTIFICATIONS_SUCCESS":
      return {
        ...state,
        isLoadingNotifications: false,
        errorNotifications: false,
        successNotifications: payload.message,
        dataNotifications: payload.data,
      };
    case "NOTIFICATIONS_SUCCESS_MESSAGE":
      return {
        ...state,
        errorNotifications: false,
        successNotifications: payload.message,
      };
    case "NOTIFICATIONS_ERROR":
      return {
        ...state,
        isLoadingNotifications: false,
        errorNotifications: payload,
        successNotifications: false,
      };
    case "NOTIFICATIONS_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
