const initialState = {
  isLoadingrRamping: false,
  errorrRamping: false,
  successrRamping: false,
  datarRamping: false,
  verifyRamping: false,
  quoteRamping: false,
  isLoadingQuoteRamping: false,
};

export const rampingReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "RAMPING_LOADING":
      return {
        ...state,
        isLoadingrRamping: true,
        errorrRamping: false,
        successrRamping: false,
      };
    case "RAMPING_LOADING_QUOTE":
      return {
        ...state,
        isLoadingQuoteRamping: true,
      };
    case "RAMPING_SUCCESS":
      return {
        ...state,
        isLoadingrRamping: false,
        errorrRamping: false,
        successrRamping: payload.message,
        datarRamping: payload.data,
      };
    case "RAMPING_VERIFY":
      return {
        ...state,
        isLoadingrRamping: false,
        errorrRamping: false,
        successrRamping: payload.message,
        verifyRamping: payload.data,
      };
    case "RAMPING_MESSAGE":
      return {
        ...state,
        isLoadingrRamping: false,
        errorrRamping: false,
        successrRamping: payload.message,
      };
    case "RAMPING_QUOTE":
      return {
        ...state,
        errorrRamping: false,
        quoteRamping: payload.data,
        isLoadingQuoteRamping: false,
      };
    case "RAMPING_SUCCESS_MESSAGE":
      return {
        ...state,
        errorrRamping: false,
        successrRamping: payload.message,
      };
    case "RAMPING_ERROR":
      return {
        ...state,
        isLoadingrRamping: false,
        errorrRamping: payload,
        successrRamping: false,
      };
    case "RAMPING_ERROR_QUOTE":
      return {
        ...state,
        isLoadingrRamping: false,
        errorrRamping: payload,
        successrRamping: false,
        quoteRamping: false,
        isLoadingQuoteRamping: false,
      };
    case "RAMPING_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
