import axios from "axios";
///https://r2r-back-8625168d9521.herokuapp.com/doc/
///https://goldfish-app-aie3h.ondigitalocean.app/doc/
const axiosParams = {
  baseURL: "https://goldfish-app-aie3h.ondigitalocean.app/api",
  // timeout: 10000,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
};

const exchangeParams = {
  baseURL:
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=euro-coin",
};

const publicAxios = axios.create(axiosParams);
const privateAxios = axios.create(axiosParams);
export const exchangeAxios = axios.create(exchangeParams);

export const setJWT = (jwt) => {
  privateAxios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
};

export const codeInterceptor = (handler) => {
  privateAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            handler();
            break;
          default:
            console.log(error);
        }
      } else {
        console.log(error);
      }
      return Promise.reject(error);
    }
  ); // end use
};

export const naxios = publicAxios;
export const paxios = privateAxios;
