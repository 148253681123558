const initialState = {
  isLoadingShares: false,
  errorShares: false,
  successShares: false,
  dataShares: false,
};

export const sharesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SHARES_LOADING":
      return {
        ...state,
        isLoadingShares: true,
        errorShares: false,
        successShares: false,
      };
    case "SHARES_SUCCESS":
      return {
        ...state,
        isLoadingShares: false,
        errorShares: false,
        successShares: payload.message,
        dataShares: payload.data,
      };
    case "SHARES_SUCCESS_MESSAGE":
      return {
        ...state,
        errorShares: false,
        successShares: payload.message,
      };
    case "SHARES_STATUS":
      return {
        ...state,
        successShares: payload.message,
      };
    case "SHARES_ERROR":
      return {
        ...state,
        isLoadingShares: false,
        errorShares: payload,
        successShares: false,
      };
    case "SHARES_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
