import { combineReducers } from "redux";
import { referralReducer } from "./Referral";
import { securityReducer } from "./Security";
import { depositReducer } from "./Deposit";
import { claimReducer } from "./Claim";
import { withdrawReducer } from "./Withdraw";
import { vaultReducer } from "./Vault";
import { sharesReducer } from "./Shares";
import { tokenReducer } from "./Token";
import { rampingReducer } from "./Ramping";
import { notificationsReducer } from "./Notifications";
import { estimateReducer } from "./Estimate";
import { paymentsReducer } from "./Payments";
import { adminReducer } from "./Admin";

export const rootReducer = combineReducers({
  referral: referralReducer,
  security: securityReducer,
  deposit: depositReducer,
  claim: claimReducer,
  withdraw: withdrawReducer,
  vault: vaultReducer,
  shares: sharesReducer,
  token: tokenReducer,
  ramping: rampingReducer,
  notifications: notificationsReducer,
  estimate: estimateReducer,
  payments: paymentsReducer,
  admin: adminReducer,
});
