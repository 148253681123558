const initialState = {
  isLoadingDeposit: false,
  errorDeposit: false,
  successDeposit: false,
  dataDeposit: false,
};

export const depositReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "DEPOSIT_LOADING":
      return {
        ...state,
        isLoadingDeposit: true,
        errorDeposit: false,
        successDeposit: false,
      };
    case "DEPOSIT_SUCCESS":
      return {
        ...state,
        isLoadingDeposit: false,
        errorDeposit: false,
        successDeposit: payload.message,
        dataDeposit: payload.data,
      };
    case "DEPOSIT_SUCCESS_MESSAGE":
      return {
        ...state,
        errorDeposit: false,
        successDeposit: payload.message,
      };
    case "DEPOSIT_ERROR":
      return {
        ...state,
        isLoadingDeposit: false,
        errorDeposit: payload,
        successDeposit: false,
      };
    case "DEPOSIT_STATUS":
      return {
        ...state,
        successDeposit: payload.message,
      };
    case "DEPOSIT_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
