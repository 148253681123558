const initialState = {
  isLoadingWithdraw: false,
  errorWithdraw: false,
  successWithdraw: false,
  dataWithdraw: false,
};

export const withdrawReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "WITHDRAW_LOADING":
      return {
        ...state,
        isLoadingWithdraw: true,
        errorWithdraw: false,
        successWithdraw: false,
      };
    case "WITHDRAW_SUCCESS":
      return {
        ...state,
        isLoadingWithdraw: false,
        errorWithdraw: false,
        successWithdraw: payload.message,
        dataWithdraw: payload.data,
      };
    case "WITHDRAW_SUCCESS_MESSAGE":
      return {
        ...state,
        errorWithdraw: false,
        successWithdraw: payload.message,
      };
    case "WITHDRAW_ERROR":
      return {
        ...state,
        isLoadingWithdraw: false,
        errorWithdraw: payload,
        successWithdraw: false,
      };
    case "WITHDRAW_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
