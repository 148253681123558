import {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useState,
} from "react";
import usePimlico from "../hooks/usePimlico";
import { useAccount, useSwitchChain, useDisconnect } from "wagmi";
import ModalSuccess from "../components/Modal/ModalSuccess";
import Button from "../components/ButtonAlt";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export const WalletProviderContext = createContext();
export const WalletProviderProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    isConnected,
    chain,
    address,
    connector: activeConnector,
  } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const { disconnectAsync } = useDisconnect();
  const { smartAccountAddress, isLoadingSafeWallet, getSmartAccountClient } =
    usePimlico();
  const [isWrongNetwork, setisWrongNetwork] = useState(false);
  const [isWrongWalletAccount, setisWrongWalletAccount] = useState(false);
  const { eoaSecurity } = useSelector(({ security }) => security);
  const logout = async () => {
    await disconnectAsync({
      connector: activeConnector,
    });
    dispatch({
      type: "DISCONNECT",
    });
    window.location.reload();
  };
  const switchAccountRecursive = useCallback(async () => {
    try {
      console.log("eoa magic", address);
      console.log("eoa cache", eoaSecurity);
      if (
        !!eoaSecurity &&
        address?.toLocaleLowerCase() !== eoaSecurity?.toLocaleLowerCase() &&
        isConnected
      ) {
        setisWrongWalletAccount(() => true);
      } else {
        setisWrongWalletAccount(() => false);
      }
    } catch (e) {
      console.error(e);
      setisWrongWalletAccount(() => true);
      const timeout = setTimeout(async () => {
        await switchAccountRecursive();
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [isConnected, address, eoaSecurity]);
  const switchChainRecursive = useCallback(async () => {
    try {
      if (chain?.id !== 137 && isConnected) {
        setisWrongNetwork(() => true);
        await switchChainAsync({
          chainId: 137,
        });
      } else {
        setisWrongNetwork(() => false);
      }
    } catch (e) {
      console.error(e);
      const timeout = setTimeout(async () => {
        await switchChainRecursive();
      }, 5000);

      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain, isConnected]);

  useEffect(() => {
    switchChainRecursive();
    switchAccountRecursive();
  }, [switchChainRecursive, switchAccountRecursive]);
  return (
    <WalletProviderContext.Provider
      value={{
        smartAccountAddress,
        isLoadingSafeWallet,
        getSmartAccountClient,
        isWrongNetwork,
      }}
    >
      <ModalSuccess
        visible={isWrongWalletAccount}
        onClose={() => {}}
        info={{
          status: "ExecReverted",
          extra: (
            <>
              <h6 className="bold" style={{ textAlign: "center" }}>
                You are on the wrong wallet account. Please switch to the
                correct account and refresh the page.
              </h6>
              <Button
                icon_left="log-out-03"
                full
                box
                secondary
                onClick={() => logout()}
              >
                {t("r2r-141")}
              </Button>
            </>
          ),
        }}
      />
      <ModalSuccess
        visible={isWrongNetwork}
        onClose={() => {}}
        info={{
          status: "ExecReverted",
          extra: (
            <h6 className="bold" style={{ textAlign: "center" }}>
              You are on the wrong network. Please switch to Polygon and refresh
              the page.
            </h6>
          ),
        }}
      />
      {children}
    </WalletProviderContext.Provider>
  );
};

// Hook
export const useWalletProvider = () => useContext(WalletProviderContext);
