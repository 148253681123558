const initialState = {
  isLoadingVault: false,
  errorVault: false,
  successVault: false,
  dataVault: false,
  listVault: false,
};

export const vaultReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "VAULT_LOADING":
      return {
        ...state,
        isLoadingVault: true,
        errorVault: false,
        successVault: false,
        dataVault: false,
      };

    case "VAULT_SUCCESS":
      return {
        ...state,
        isLoadingVault: false,
        errorVault: false,
        successVault: payload.message,
        dataVault: payload.data,
      };
    case "VAULT_LIST_VAULTS":
      return {
        ...state,
        isLoadingVault: false,
        errorVault: false,
        successVault: payload.message,
        listVault: payload.data,
      };

    case "VAULT_SUCCESS_MESSAGE":
      return {
        ...state,
        errorVault: false,
        successVault: payload.message,
      };
    case "VAULT_ERROR":
      return {
        ...state,
        isLoadingVault: false,
        errorVault: payload,
        successVault: false,
      };
    case "VAULT_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
