import { Suspense, lazy } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import Spinner from "./components/Spinner";
import { createConfig, WagmiProvider } from "wagmi";
import { http } from "viem";
import { WalletProviderProvider } from ".//context/WalletProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { polygon } from "wagmi/chains";
import { dedicatedWalletConnector } from "@magiclabs/wagmi-connector";
import LogoutHandler from "./utils/jsx/LogoutHandler";
import { injected } from "wagmi/connectors";

const Home = lazy(() => import("./pages/Home"));
const Terms = lazy(() => import("./pages/Terms"));
const Legal = lazy(() => import("./pages/Legal"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Profile = lazy(() => import("./pages/Profile"));
const Participate = lazy(() => import("./pages/Participate"));
const Notifications = lazy(() => import("./pages/Notifications"));
const Positions = lazy(() => import("./pages/Positions"));
const Referral = lazy(() => import("./pages/Referral"));
const FAQS = lazy(() => import("./pages/FAQS"));
const Docs = lazy(() => import("./pages/Docs"));
const Token = lazy(() => import("./pages/Token"));
const Admin = lazy(() => import("./pages/Admin"));
// const Payments = lazy(() => import("./pages/Payments"));
const queryClient = new QueryClient();

export const config = createConfig({
  chains: [polygon],
  transports: {
    [polygon.id]: http(process.env.REACT_APP_RPC_URL),
  },
  autoConnect: true,
  connectors: [
    dedicatedWalletConnector({
      chains: [polygon],
      options: {
        apiKey: process.env.REACT_APP_MAGIC_API_KEY,
        isDarkMode: false,
        oauthOptions: {
          providers: ["google", "github", "twitter"],
          // providers: ["google", "github", "discord", "linkedin", "twitter"],
        },
        magicSdkConfiguration: {
          network: {
            rpcUrl: process.env.REACT_APP_RPC_URL,
            chainId: polygon.id,
          },
        },
      },
    }),
    injected(),
    // metaMask({
    //   chains: [polygon],
    //   dappMetadata: {
    //     name: "R2R DEFI",
    //     description: "DEFI",
    //   },
    //   extensionOnly: true,
    //   forceDeleteProvider: true,
    //   forceInjectProvider: true,
    //   infuraAPIKey: process.env.REACT_APP_RPC_URL,
    //   shouldShimWeb3: true,
    // }),
  ],
});

function App() {
  return (
    <Suspense fallback={<Spinner full />}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <WalletProviderProvider>
            <LogoutHandler />
            <BrowserRouter>
              <Routes>
                <Route path="/">
                  <Route index element={<Home />} />
                  <Route path="/:referral" element={<Home />} />
                </Route>
                <Route path="/legal" element={<Legal />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/participate" element={<Participate />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/positions" element={<Positions />} />
                <Route path="/referral" element={<Referral />} />
                <Route path="/faq" element={<FAQS />} />
                <Route path="/docs" element={<Docs />} />
                <Route path="/tokenr2r" element={<Token />} />
                <Route path="/admin" element={<Admin />} />
                {/* <Route path="/payments">
                  <Route index element={<Payments />} />
                  <Route path="/payments/:id" element={<Payments />} />
                </Route> */}
                <Route path="*" element={<Navigate to={"/"} />} />
              </Routes>
            </BrowserRouter>
          </WalletProviderProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </Suspense>
  );
}

export default App;
